import { isDesktop } from "react-device-detect";

import React, { Suspense, useEffect } from "react";
import styled from "styled-components";
import { AnimatePresence } from "framer-motion";

import { useAnimContext } from "./contexts/animationContext";

import WelcomeScene from "./scenes/welcome";

import WelcomeBtn from "./components/welcome/welcomeBtn";

import Home from "./components/home";
import About from "./components/about";
import Portfolio from "./components/portfolio";

const WelcomeDiv = styled.div`
  position: fixed;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: black;
`;

const MainContainer = styled.div`
position: relative;
height: 100vh;
}
`;

function App() {
  const { isMobilea, setIsMobilea, page } = useAnimContext();

  useEffect(() => {
    setIsMobilea(!isDesktop);
  }, []);

  return (
    <>
      <MainContainer>
        <WelcomeBtn />
        <WelcomeDiv>
          {!isMobilea && (
            <Suspense fallback={null}>
              <WelcomeScene />
            </Suspense>
          )}
        </WelcomeDiv>

        <AnimatePresence>
          {page === "home" && <Home />}
          {page === "about" && <About />}
          {page === "work" && <Portfolio />}
        </AnimatePresence>
      </MainContainer>
    </>
  );
}
export default App;
