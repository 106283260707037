import * as THREE from "three";
import React, { useRef, useEffect } from "react";
import { MotionCanvas, LayoutCamera } from "framer-motion-3d";
import { MotionConfig } from "framer-motion";
import { useThree, useFrame, useLoader } from "@react-three/fiber";
import { useGLTF, Loader } from "@react-three/drei";
import { TextureLoader } from "three/src/loaders/TextureLoader";

import { useAnimContext } from "../contexts/animationContext";

const cameraVariants = {
  rest: { x: 10, y: 10, z: 10, fov: 40 },
  welcomeBtnClicked: {
    x: 1.35,
    y: 3.33,
    z: -1.55,
    fov: 40,
  },
  aboutBtnClicked: {
    x: -2.3,
    y: 2.21,
    z: -2.15,
    fov: 40,
  },
  workBtnClicked: {
    x: -3,
    y: 2.18,
    z: -0.4,
    fov: 40,
  },
};

const transition = {
  duration: 2,
};

var lastLookAt = { x: 0, y: 1.5, z: 0 };
var restLookAt = { x: 0, y: 1.5, z: 0 };
var welcomeBtnClickedLookat = { x: 1.35, y: 3.33, z: -3.9 };
var aboutBtnClickedLookat = { x: -2.61, y: 2.21, z: -2.15 };
var workBtnClickedLookat = { x: -3.19, y: 2.18, z: -0.4 };

function WelcomeScene() {
  const {
    setPage,
    goToRest,
    setGoToRest,
    welcomeBtnClicked,
    aboutBtnClicked,
    workBtnClicked,
  } = useAnimContext();

  const getanimationType = () => {
    if (goToRest) {
      return "rest";
    } else {
      if (welcomeBtnClicked) {
        return "welcomeBtnClicked";
      } else if (aboutBtnClicked) {
        return "aboutBtnClicked";
      } else if (workBtnClicked) {
        return "workBtnClicked";
      } else {
        return "rest";
      }
    }
  };

  const setAnimationDone = () => {
    if (goToRest) {
      setGoToRest(false);
    } else {
      if (welcomeBtnClicked) {
        lastLookAt = welcomeBtnClickedLookat;
        setPage("home");
      }
      if (aboutBtnClicked) {
        lastLookAt = aboutBtnClickedLookat;
        setPage("about");
      }
      if (workBtnClicked) {
        lastLookAt = workBtnClickedLookat;
        setPage("work");
      }
    }
  };

  return (
    <MotionConfig transition={transition}>
      <MotionCanvas
        dpr={[1, 2]}
        shadows
        resize={{ scroll: false, offsetSize: true }}
      >
        <Lights />
        <LayoutCamera
          shadowMap
          variants={cameraVariants}
          initial="rest"
          animate={getanimationType()}
          onAnimationComplete={setAnimationDone}
        />
        <CamControl
          goToRest={goToRest}
          welcomeBtnClicked={welcomeBtnClicked}
          aboutBtnClicked={aboutBtnClicked}
          workBtnClicked={workBtnClicked}
        />
        <Room
          welcomeBtnClicked={welcomeBtnClicked}
          aboutBtnClicked={aboutBtnClicked}
          workBtnClicked={workBtnClicked}
          goToRest={goToRest}
        />
      </MotionCanvas>
      <Loader />
    </MotionConfig>
  );
}

function CamControl({
  goToRest,
  welcomeBtnClicked,
  aboutBtnClicked,
  workBtnClicked,
}) {
  const three = useThree();

  useEffect(() => {
    const resize = () => {
      three.setSize(window.innerWidth, window.innerHeight);
      three.camera.aspect = window.innerWidth / window.innerHeight;
      three.camera.updateProjectionMatrix();
      if (window.innerWidth < 1024) {
        cameraVariants.rest.x = 15;
        cameraVariants.rest.y = 15;
        cameraVariants.rest.z = 15;
      } else {
        cameraVariants.rest.x = 10;
        cameraVariants.rest.y = 10;
        cameraVariants.rest.z = 10;
      }
    };

    window.addEventListener("resize", () => {
      resize();
    });
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [goToRest, welcomeBtnClicked, aboutBtnClicked, workBtnClicked]);

  let currentLookAt = new THREE.Vector3(
    restLookAt.x,
    restLookAt.y,
    restLookAt.z
  );

  let lookAt = new THREE.Vector3(
    currentLookAt.x,
    currentLookAt.y,
    currentLookAt.z
  );

  useFrame(() => {
    if (goToRest) {
      lookAt = new THREE.Vector3(lastLookAt.x, lastLookAt.y, lastLookAt.z);
      three.camera.lookAt(lookAt);
    } else {
      if (welcomeBtnClicked) {
        lookAt.lerp(welcomeBtnClickedLookat, 0.05);
        three.camera.lookAt(lookAt);
      } else if (aboutBtnClicked) {
        lookAt.lerp(aboutBtnClickedLookat, 0.05);
        three.camera.lookAt(lookAt);
      } else if (workBtnClicked) {
        lookAt.lerp(workBtnClickedLookat, 0.05);
        three.camera.lookAt(lookAt);
      } else {
        lookAt = new THREE.Vector3(restLookAt.x, restLookAt.y, restLookAt.z);
        three.camera.lookAt(lookAt);
      }
    }
  });

  return null;
}

function Lights() {
  return (
    <>
      <ambientLight intensity={0.5} />
      <pointLight
        position={[0, 10, 0]}
        intensity={2.5}
        color="#ffffff"
        castShadow
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}
      />

      <pointLight
        position={[5, 10, -5]}
        intensity={2.5}
        color="#00d4ff"
        castShadow
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}
      />
      <pointLight
        position={[5, 10, 0]}
        intensity={2.5}
        color="#ff76d5"
        castShadow
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}
      />
    </>
  );
}

function Room({
  welcomeBtnClicked,
  aboutBtnClicked,
  workBtnClicked,
  goToRest,
  ...props
}) {
  //  receiveShadow
  //  castShadow

  const loadingMap = useLoader(TextureLoader, "loading.jpg");
  const homeMap = useLoader(TextureLoader, "home.jpg");
  const aboutMap = useLoader(TextureLoader, "about.jpg");
  const workMap = useLoader(TextureLoader, "work.jpg");

  const group = useRef();
  const { nodes, materials } = useGLTF("/room.glb");
  return (
    <>
      <group ref={group} {...props} dispose={null}>
        <mesh
          receiveShadow
          castShadow
          geometry={nodes.room.geometry}
          material={materials.room}
          position={[0.03, 1.41, -3.19]}
          scale={[0.21, 0.29, 0.29]}
        />
        <mesh
          geometry={nodes.home.geometry}
          position={[1.34, 3.39, -3.81]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[1.77, 1, 0.96]}
        >
          <meshStandardMaterial
            side={THREE.DoubleSide}
            map={welcomeBtnClicked || goToRest ? loadingMap : homeMap}
          />
        </mesh>

        <mesh
          geometry={nodes.about.geometry}
          position={[-2.54, 2.2, -2.13]}
          rotation={[Math.PI / 2, Math.PI, -2.24]}
          scale={[0.74, 0.42, 0.4]}
        >
          <meshStandardMaterial
            side={THREE.DoubleSide}
            map={aboutBtnClicked || goToRest ? loadingMap : aboutMap}
          />
        </mesh>

        <mesh
          geometry={nodes.work.geometry}
          position={[-3.12, 2.2, -0.38]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.74, 0.42, 0.4]}
        >
          <meshStandardMaterial
            side={THREE.DoubleSide}
            map={workBtnClicked || goToRest ? loadingMap : workMap}
          />
        </mesh>
      </group>
    </>
  );
}

useGLTF.preload("/room.glb");
export default WelcomeScene;
