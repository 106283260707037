import React, { Suspense, useState, useEffect } from "react";
import useMeasure from "react-use-measure";

import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faGithub,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { useAnimContext } from "../../contexts/animationContext";

import HomeScene from "../../scenes/home";
import { Text3D } from "../../scenes/text";

const Container = styled(motion.div)`
  position: fixed;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.background};
`;

const SayHi = styled(motion.a)`
  position: fixed;
  top: 2.5%;
  left: 7.5%;
  width: 3rem;
  cursor: pointer;
  z-index: 3;
  text-decoration: none;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    left: calc(50% - 1.5rem);
  }
`;

const SayHiIcon = styled(motion.div)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
`;

const SayHiText = styled(motion.p)`
  color: ${(props) => props.theme.text};
  margin-left: 0.5rem;
  align-self: center;
  width: max-content;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    display: none;
  }
`;

// box

const Box = styled(motion.div)`
  position: fixed;
  top: 15%;
  left: 7.5%;
  width: 85%;
  height: 70%;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  z-index: 1;
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    display: block;
  }
`;

const SubBoxText = styled(motion.div)`
  border-radius: 10px;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    width: 100%;
    height: 50%;
  }
`;

const H1Text = styled(motion.div)`
  text-align: left;
  height: 5rem;
`;

const H2Text = styled(motion.h1)`
  margin: 1rem;
  font-size: 3vw;
  color: #fff;
  text-align: center;
  background: linear-gradient(
    271deg,
    ${(props) => props.theme.headingOne} 30%,
    ${(props) => props.theme.headingTwo} 50%,
    ${(props) => props.theme.headinThree} 70%,
    ${(props) => props.theme.headingFour} 94%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    font-size: 1.5rem;
    margin: 0.5rem;
    text-align: center;
  }
`;

const PText = styled(motion.div)`
  margin: 1rem;
  font-size: 1.5rem;
  min-height: max-content;
  color: ${(props) => props.theme.text};
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    margin: 0.5rem;
    text-align: center;
    font-size: 1rem;
  }
`;

const ButtonContainer = styled(motion.div)`
  margin: 1rem;
  margin-bottom: 1.25rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    margin: 0.5rem;
  }
`;

const Btn = styled(motion.button)`
  border: none;
  width: 100%;
  height: 2rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.button.primary};
  color: ${(props) => props.theme.button.secondary};
  text-shadow: 1px 1px 1px ${(props) => props.theme.button.tertiary};
  font-weight: bold;
  font-size: 1.25rem;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0px 5px 5px ${(props) => props.theme.button.tertiary};
  z-index: 3;

  &:hover {
    background-color: ${(props) => props.theme.button.secondary};
    color: ${(props) => props.theme.button.primary};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    margin: 0.5rem;
    font-size: 1rem;
  }
`;

const SubBoxImg = styled(motion.div)`
  width: 50%;
  height: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    width: 100%;
    height: 50%;
  }
`;

const SocialNav = styled(motion.div)`
  position: fixed;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0;
  top: 80%;
  left: calc(50% - 145px);
  width: 290px;
  height: calc(20% - 3rem);
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled(motion.a)`
  background-color: ${(props) => props.theme.button.primary};
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  cursor: pointer;
  z-index: 3;
  border: none;
  padding: 0.4rem;
  border-radius: 50%;
`;

const ClassicWebNav = styled(motion.div)`
  position: fixed;
  padding: 0;
  bottom: 0;
  left: calc(50% - 145px);
  width: max-content;
  height: calc(10% - 2.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ClassicWebsites = styled(motion.a)`
  color: ${(props) => props.theme.button.primary};
  font-size: 0.85rem;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

const ContainerVariants = {
  rest: {},
  anim: {},
  hover: {},
  press: {},
};

const BoxVariants = {
  rest: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
};

const SubBoxImgVariants = {
  rest: {
    y: "-100vh",
  },
  anim: {
    y: 0,
    transition: {
      delay: 0.5,
      duration: 0.5,
    },
  },
};

const H2Variants = {
  rest: {
    y: "-100vh",
  },
  anim: {
    y: 0,
    transition: {
      delay: 2,
    },
  },
};

const H3Variants = {
  rest: {
    y: "-100vh",
  },
  anim: {
    y: 0,
    transition: {
      delay: 2.5,
    },
  },
};
const PTextVariants = {
  rest: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      delay: 3,
    },
  },
};
const ButtonVariant = {
  rest: { scale: 0, opacity: 0 },
  anim: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 3.5,
      type: "spring",
      bounce: 0.5,
      duration: 1,
    },
  },
  hover: {
    scale: [1, 0.9, 1],
    transition: {
      type: "spring",
      duration: 0.5,
      bounce: 0.5,
      repeat: Infinity,
    },
  },
  press: {
    scale: [1, 1.1, 1],
    transition: {
      type: "spring",
      duration: 0.1,
      bounce: 0.5,
    },
  },
};

const SocialNavVariants = {
  rest: { scale: 0, opacity: 0 },
  anim: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 4,
      type: "spring",
      bounce: 0.5,
      duration: 1,
    },
  },
  hover: {},
  press: {},
};

const IconVariants = {
  rest: {},
  anim: {},
  hover: {
    scale: [1, 0.9, 1],
    transition: {
      type: "spring",
      duration: 0.5,
      bounce: 0.5,
      repeat: Infinity,
    },
  },
  press: {
    scale: [1, 1.1, 1],
    transition: {
      type: "spring",
      duration: 0.1,
      bounce: 0.5,
    },
  },
};

const textArray = [
  "I am a full-stack web developer with a passion for creating beautiful, responsive websites and web applications.",
  "I am a game developer with a passion for creating engaging, interactive games.",
  "I am a software engineer with a passion for creating elegant, scalable software.",
];
let textIndex = 0;
let timeOut = null;

function Home() {
  const { gotoAbout, gotoWork, page } = useAnimContext();
  const [showHome, setShowHome] = useState(true);
  const [charLanded, setCharLanded] = useState(false);

  const [ref, bounds] = useMeasure({ scroll: false });
  const [refText, boundsText] = useMeasure({ scroll: false });

  const [showText, setShowText] = useState(false);
  const [showTextRunning, setShowTextRunning] = useState(false);

  const changeText = () => {
    setShowTextRunning(true);
    setShowText(false);
    if (textIndex < textArray.length - 1) {
      textIndex++;
    } else {
      textIndex = 0;
    }
    setShowText(true);
    if (page === "home") timeOut = setTimeout(changeText, 5000);
  };

  useEffect(() => {
    if (!showTextRunning && page === "home")
      timeOut = setTimeout(changeText, 2500);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  const SplitText = ({ children, ...rest }) => {
    let words = children.split(" ");
    return words.map((word, i) => {
      return (
        <div
          key={children + i}
          style={{ display: "inline-block", overflow: "hidden" }}
        >
          <motion.div
            {...rest}
            style={{ display: "inline-block", willChange: "transform" }}
            custom={i}
          >
            {word + (i !== words.length - 1 ? "\u00A0" : "")}
          </motion.div>
        </div>
      );
    });
  };

  return (
    <>
      <AnimatePresence>
        {showHome && (
          <Container
            variants={ContainerVariants}
            initial="rest"
            animate="anim"
            exit="rest"
          >
            <SayHi
              href="mailto: gunathilakesharada@gmail.com"
              variants={SocialNavVariants}
              initial="rest"
              animate="anim"
              exit="rest"
            >
              <SayHiIcon
                variants={IconVariants}
                whileHover="hover"
                whileTap="press"
                style={{
                  color: "white",
                }}
              >
                <FontAwesomeIcon icon={faEnvelope} size="3x" />
                <SayHiText>gunathilakesharada@gmail.com</SayHiText>
              </SayHiIcon>
            </SayHi>
            <Box variants={BoxVariants}>
              <SubBoxText>
                <H1Text ref={refText} variants={H2Variants}>
                  <Text3D
                    bounds={boundsText}
                    text={"Hi, I'm Sharada,"}
                    size={18}
                  />
                </H1Text>

                <H2Text variants={H3Variants}>A Software Developer.</H2Text>
                <PText variants={PTextVariants}>
                  {showText && (
                    <SplitText
                      initial={{ y: "100%" }}
                      animate="visible"
                      variants={{
                        visible: (i) => ({
                          y: 0,
                          transition: {
                            delay: i * 0.025,
                          },
                        }),
                      }}
                    >
                      {textArray[textIndex]}
                    </SplitText>
                  )}
                </PText>

                <ButtonContainer>
                  <Btn
                    variants={ButtonVariant}
                    initial="rest"
                    animate="anim"
                    whileHover="hover"
                    whileTap="press"
                    onClick={() => {
                      gotoAbout();
                      setShowHome(false);
                    }}
                  >
                    About Me
                  </Btn>
                  <Btn
                    variants={ButtonVariant}
                    initial="rest"
                    animate="anim"
                    whileHover="hover"
                    whileTap="press"
                    onClick={() => {
                      gotoWork();
                      setShowHome(false);
                    }}
                  >
                    Projects
                  </Btn>
                </ButtonContainer>
              </SubBoxText>
              <SubBoxImg
                ref={ref}
                variants={SubBoxImgVariants}
                onAnimationComplete={() => {
                  setCharLanded(true);
                }}
              >
                <Suspense fallback={null}>
                  <HomeScene bounds={bounds} charLanded={charLanded} />
                </Suspense>
              </SubBoxImg>
            </Box>
            <SocialNav
              variants={SocialNavVariants}
              initial="rest"
              animate="anim"
              exit="rest"
            >
              <Icon
                variants={IconVariants}
                whileHover="hover"
                whileTap="press"
                target={`_blank`}
                href="https://www.facebook.com/sharada"
              >
                <FontAwesomeIcon
                  style={{
                    color: "#1e90ff",
                  }}
                  icon={faFacebook}
                  size="3x"
                />
              </Icon>
              <Icon
                variants={IconVariants}
                whileHover="hover"
                whileTap="press"
                target={`_blank`}
                href="https://www.instagram.com/sharada_gunathilake/"
              >
                <FontAwesomeIcon
                  style={{
                    color: "purple",
                  }}
                  icon={faInstagram}
                  size="3x"
                />
              </Icon>
              <Icon
                variants={IconVariants}
                whileHover="hover"
                whileTap="press"
                target={`_blank`}
                href="https://github.com/sharada-g"
              >
                <FontAwesomeIcon
                  style={{
                    color: "black",
                  }}
                  icon={faGithub}
                  size="3x"
                />
              </Icon>
              <Icon
                variants={IconVariants}
                whileHover="hover"
                whileTap="press"
                target={`_blank`}
                href="https://www.linkedin.com/in/sharadagunathilake/"
              >
                <FontAwesomeIcon
                  style={{
                    color: "blue",
                  }}
                  icon={faLinkedin}
                  size="3x"
                />
              </Icon>
            </SocialNav>
            <ClassicWebNav
              variants={SocialNavVariants}
              initial="rest"
              animate="anim"
              exit="rest"
            >
              <ClassicWebsites
                variants={IconVariants}
                whileHover="hover"
                whileTap="press"
                href="http://www.sharadagunathilake.info?cfn=true"
                target="_blank"
              >
                click to visit my classic website for more information!
              </ClassicWebsites>
            </ClassicWebNav>
          </Container>
        )}
      </AnimatePresence>
    </>
  );
}

export default Home;
