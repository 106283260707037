export var aboutData = [
  {
    id: 0,
    type: "about",
    title: "My name is Sharada Gunathilake",
    subtitle: "A software developer",
    date: "",
    image: "./about/about.jpg",
    description: [
      "I am a game, software, and web developer based in Tasmania, Australia.",
      "I create well-organized full-stack web applications and develop games and software.",
    ],
  },

  {
    id: 1,
    type: "work",
    title: "Software Engineering Trainer",
    subtitle: "Institute of Data, Sydney",
    date: "Apr 2022 - Present",
    image: "./about/iod.jpeg",
    description: [
      "Train and introduce students to software engineering for full-stack web development",
    ],
  },

  {
    id: 2,
    type: "",
    title: "Web Developer/IT Support",
    subtitle: "School24 Pty Ltd, Sydney",
    date: "Apr 2022 - Present",
    image: "./about/school24.jpg",
    description: [
      "Using ASP.NET C#, Angular, and ReactJS, design and develop web applications.",
    ],
  },

  {
    id: 3,
    type: "",
    title: "Software Developer",
    subtitle: "Tasrah Pty Ltd",
    date: "May 2021 - Apr 2022",
    image: "./about/tasrah.jpg",
    description: [
      "Developing full-stack web-applications for Tasmanian and International companies using Laravel, Python, React.js, and Vue.js.",
    ],
  },

  {
    id: 4,
    type: "",
    title: "Web App Developer",
    subtitle: "Anderson Morgan",
    date: "Jan 2020 - May 2021",
    image: "./about/am.jpg",
    description: [
      "Developed multiple web-based logistics management software using the Laravel PHP framework and Vue.js.",
      "Assisted in developing mobile applications using React Native.",
    ],
  },

  {
    id: 5,
    type: "",
    title: "Web Developer",
    subtitle: "AIMS Computers Int. (PVT) Ltd",
    date: "Jun 2014 - Sep 2014",
    image: "./about/aims.jpg",
    description: [
      "Developed websites and Content Management Systems (CMS) for websites, which allow customers to update further and maintain their websites.",
      "Used programming languages such as HTML, CSS, JavaScript, jQuery, PHP, and MySQL.",
    ],
  },

  {
    id: 6,
    type: "",
    title: "Game Designer",
    subtitle: "Findmyfare.com",
    date: "Jun 2014 - Sep 2014",
    image: "./about/findmyfair.jpg",
    description: [
      "Developed a Facebook game to help the company promote their branding and services.",
      "Used programming languages such as C#, python, PHP, and developing tools such as Unity game engine, Facebook developer API.",
    ],
  },

  {
    id: 7,
    type: "education",
    title: "Clinton Institute",
    subtitle: "Advanced Diploma of Leadership and Management",
    date: "2020 - 2021",
    image: "./about/clinton.jpg",
    description: [
      "This Advanced Diploma provides a pathway to work in the private and public sectors of business and small and large enterprises to manage people and resources in a critical function.",
    ],
  },

  {
    id: 8,
    type: "",
    title: "Australian Computer Society",
    subtitle: "Professor Year Program",
    date: "2019 - 2020",
    image: "./about/acs.jpg",
    description: [
      "ACS Professional Year Program to help adapt to the Australian ICT Standards and work.",
    ],
  },

  {
    id: 9,
    type: "",
    title: "University of Tasmania",
    subtitle: "Master of Information Technology and Systems",
    date: "2016 - 2018",
    image: "./about/utas.jpg",
    description: [
      "Programming units: Mobile and Software Application development, Embedded systems, Game Design and Production, Computer Graphics and animation, and Network Security Techniques and Technology.",
    ],
  },

  {
    id: 10,
    type: "",
    title: "Buckinghamshire New University",
    subtitle: "Bachelor of Science Computing, Interactive Media",
    date: "2011 - 2014",
    image: "./about/bucks.jpg",
    description: [
      "Programming units: Graphical Application Development, Software Engineering, and Advanced Interactive Programming.",
    ],
  },
];
