import React, { useState, Suspense, useEffect } from "react";
import useMeasure from "react-use-measure";

import styled from "styled-components";
import { motion, AnimatePresence, useMotionValue } from "framer-motion";
import { useProgress } from "@react-three/drei";
import { useAnimContext } from "../../contexts/animationContext";

import { WelcomeBtnScene } from "../../scenes/welcomeBtn";
import { Text3D } from "../../scenes/text";

const MobileLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.background};
  z-index: 1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    font-size: 1rem;
    font-weight: bold;
    color: ${(props) => props.theme.text};
  }
`;

const WelcomeHeaderBackgroundDiv = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 18px;
  background-color: ${(props) => props.theme.cardBackgroundSecondary};
  clip-path: ${(props) =>
    props.welcomeanimdone === "true"
      ? "circle(135px at 80% 20%);"
      : "circle(300px at 50% -20%);"}
  

  transition: 0.5s ease-in-out;
`;

const WelcomeHeaderSceneDiv = styled(motion.div)`
  position: fixed;
  right: 0;
  height: 17.5rem;
  width: 100%;
  bottom: calc(50% - 0.5rem);
`;

const WelcomeDiv = styled(motion.div)`
  position: fixed;
  top: calc(50% - 13rem);
  left: calc(50% - 10rem);
  width: 20rem;
  height: 26rem;
  border-radius: 18px;
  background: ${(props) => props.theme.cardBackgroundPrimary};
  backdrop-filter: blur(7.5px);
  box-shadow: 15px 15px 15px ${(props) => props.theme.cardBackgroundTertiary};
  z-index: 2;

  &:hover ${WelcomeHeaderBackgroundDiv} {
    clip-path: circle(300px at 50% -20%);
  }
`;

const WelcomeContentText3dContainer = styled(motion.div)`
  height: 4rem;
  width: 18rem;
`;

const WelcomeContentDiv = styled(motion.div)`
  position: fixed;
  bottom: calc(50% - 10rem);
  left: calc(50% - 10rem);
  padding: 1rem;

  h1 {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(
      271deg,
      ${(props) => props.theme.headingOne} 30%,
      ${(props) => props.theme.headingTwo} 50%,
      ${(props) => props.theme.headinThree} 70%,
      ${(props) => props.theme.headingFour} 94%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const PText = styled(motion.div)`
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  color: ${(props) => props.theme.text};
`;

const WelcomeButton = styled(motion.button)`
  position: fixed;
  bottom: calc(50% - 12rem);
  background: none;
  border: none;
  left: calc(50% - 9rem);
  width: 18rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.button.primary};
  color: ${(props) => props.theme.button.secondary};
  text-shadow: 1px 1px 1px ${(props) => props.theme.button.tertiary};
  height: 2rem;
  font-weight: bold;
  font-size: 1.25rem;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0px 5px 5px ${(props) => props.theme.button.tertiary};
  z-index: 3;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.button.secondary};
    color: ${(props) => props.theme.button.primary};
  }
  &:hover ${WelcomeHeaderBackgroundDiv} {
    clip-path: circle(300px at 50% -20%);
  }
`;

const WelcomeDivVariant = {
  rest: { y: "-100vh" },
  anim: {
    y: 0,
    transition: {
      delay: 2,
      type: "spring",
      bounce: 0.6,
      duration: 0.5,
    },
  },
  hover: {
    scale: 1.1,
  },
  press: {},
  exit: {
    opacity: [1, 0],
    y: "-100vh",
    transition: {
      type: "spring",
      bounce: 0.6,
      duration: 0.5,
    },
  },
};

const WelcomeContentDivVarianth1 = {
  rest: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      delay: 2.5,
      duration: 1,
    },
  },
};
const WelcomeContentDivVariantp1 = {
  rest: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      delay: 3.5,
      duration: 1,
    },
  },
};
const PTextVariants = {
  rest: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      delay: 4.5,
      duration: 1,
    },
  },
};

const WelcomeButtonVariant = {
  rest: { scale: 0, opacity: 0 },
  anim: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 5.5,
      type: "spring",
      bounce: 0.5,
      duration: 1,
    },
  },
  hover: {
    scale: [1, 0.9, 1],
    transition: {
      type: "spring",
      duration: 0.5,
      bounce: 0.5,
      repeat: Infinity,
    },
  },
  press: {
    scale: [1, 1.1, 1],
    transition: {
      type: "spring",
      duration: 0.1,
      bounce: 0.5,
    },
  },
};

function WelcomeBtn() {
  const {
    isMobilea,
    loading,
    setLoading,
    progress,
    setProgress,
    welcomeBtnClicked,
    goToHome,
  } = useAnimContext();

  const [welcomeAnimDone, setWelcomeAnimDone] = useState(false);

  const [isHover, setIsHover] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const [ref, bounds] = useMeasure({ scroll: false });
  const [refText, boundsText] = useMeasure({ scroll: false });

  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const resetMousePosition = () => {
    mouseX.set(0);
    mouseY.set(0);
  };
  function Loader() {
    const { active, progress } = useProgress();

    useEffect(() => {
      setLoading(active);
      setProgress(progress);
    }, [active, progress]);

    return <></>;
  }

  // text anim
  const SplitText = ({ children, ...rest }) => {
    let words = children.split(" ");
    return words.map((word, i) => {
      return (
        <div
          key={children + i}
          style={{ display: "inline-block", overflow: "hidden" }}
        >
          <motion.div
            {...rest}
            style={{ display: "inline-block", willChange: "transform" }}
            custom={i}
          >
            {word + (i !== words.length - 1 ? "\u00A0" : "")}
          </motion.div>
        </div>
      );
    });
  };

  return (
    <>
      {isMobilea && loading && (
        <MobileLoading>
          <h1>Loading {parseInt(progress)}%..</h1>
        </MobileLoading>
      )}
      <AnimatePresence>
        {!isPressed && (
          <WelcomeDiv
            ref={ref}
            variants={WelcomeDivVariant}
            initial="rest"
            animate={isMobilea ? (loading ? "rest" : "anim") : "anim"}
            whileHover="hover"
            whileTap="press"
            exit="exit"
            onHoverStart={() => {
              resetMousePosition();
              setIsHover(true);
            }}
            onHoverEnd={() => {
              resetMousePosition();
              setIsHover(false);
            }}
            onPointerMove={(e) => {
              mouseX.set(e.clientX - bounds.x - bounds.width / 2);
              mouseY.set(e.clientY - bounds.y - bounds.height / 2);
            }}
          >
            <WelcomeHeaderBackgroundDiv
              welcomeanimdone={welcomeAnimDone ? "true" : "false"}
            />
            <WelcomeHeaderSceneDiv>
              <Suspense fallback={<Loader />}>
                <WelcomeBtnScene
                  welcomeAnimDone={welcomeAnimDone}
                  isHover={isHover}
                  mouseX={mouseX}
                  mouseY={mouseY}
                />
              </Suspense>
            </WelcomeHeaderSceneDiv>

            <WelcomeContentDiv>
              <WelcomeContentText3dContainer
                ref={refText}
                variants={WelcomeContentDivVarianth1}
              >
                <Text3D bounds={boundsText} text={"Hello there..."} size={20} />
              </WelcomeContentText3dContainer>
              <motion.h1
                variants={WelcomeContentDivVariantp1}
                onAnimationComplete={() => {
                  if (isMobilea) {
                    if (!loading) {
                      setWelcomeAnimDone(true);
                    }
                  } else setWelcomeAnimDone(true);
                }}
              >
                Thanks for visiting my website.
              </motion.h1>
              <PText variants={PTextVariants}>
                <SplitText
                  initial={{ y: "100%" }}
                  animate="visible"
                  variants={{
                    visible: (i) => ({
                      y: 0,
                      transition: {
                        delay: i * 0.025,
                      },
                    }),
                  }}
                >
                  Click the button below to get started!
                </SplitText>
              </PText>
            </WelcomeContentDiv>

            <WelcomeButton
              variants={WelcomeButtonVariant}
              onTap={() => {
                if (!welcomeBtnClicked) {
                  setIsPressed(true);
                  goToHome();
                }
              }}
            >
              Let's go!
            </WelcomeButton>
          </WelcomeDiv>
        )}
      </AnimatePresence>
    </>
  );
}

export default WelcomeBtn;
