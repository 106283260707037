import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
    *,*::before,*::after,h1,h2,h3,h4,h5,h5 {
        margin: 0;
        padding: 0;
    }
    h1,h2,h3,h4,h5,h5{
        display: inline-block;
        font-weight: 500;
    }

    body {
      font-family: 'Lato', sans-serif;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }
    button {
     font-family: 'Lato', sans-serif;
    }
    `;

export const LightTheme = {
  background: " rgba(0, 0, 0, 1)",

  cardBackgroundPrimary: "  rgba(25, 25, 25, 0.85)",
  cardBackgroundSecondary: " rgba(255, 255, 255, 0.85)",
  cardBackgroundTertiary: " rgba(0, 0, 0, 0.5)",
  cardBackgroundFourth: " rgba(50, 50, 50, 0.5)",

  headingOne: "#833ab4",
  headingTwo: "#fd1d1d",
  headinThree: "#fcb045",
  headingFour: "#77ff87",

  text: "rgba(255, 255, 255, 1)",

  button: {
    primary: "rgba(255, 255, 255, 1)",
    secondary: "rgba(64, 136, 255, 1)",
    tertiary: "rgba(0, 0, 0, 0.5)",
  },
  breakpoints: {
    laptop: "1024px",
  },
};
