import React, { useRef, useEffect, useState } from "react";

import { MotionCanvas, LayoutCamera } from "framer-motion-3d";
import { useThree, useFrame } from "@react-three/fiber";
import { useGLTF, useAnimations, Loader } from "@react-three/drei";

var cameraPos = { x: 0, y: -1, z: 12.85 };
var parentBound = null;
var interval = 5000;

export default function HomeScene({ bounds, charLanded }) {
  const [charAction, setCharAction] = useState("air");

  useEffect(() => {
    parentBound = bounds;
  }, [bounds]);

  const noIdle = () => {
    var random = Math.floor(Math.random() * (60 - 30 + 1)) + 30;
    interval = random * 1000;

    setCharAction("greet");
    setTimeout(() => {
      setCharAction("idle");
    }, 1500);

    setTimeout(() => {
      noIdle();
    }, interval);
  };

  useEffect(() => {
    if (charLanded) {
      setCharAction("land");
      setTimeout(() => {
        setCharAction("idle");
      }, 750);
      setTimeout(() => {
        setCharAction("greet");
      }, 1000);
      setTimeout(() => {
        setCharAction("idle");
      }, 4000);
      setTimeout(() => {
        noIdle();
      }, 30000);
    }
  }, [charLanded]);

  return (
    <>
      <MotionCanvas dpr={[1, 2]} shadows>
        <Lights />
        <LayoutCamera shadowMap />
        <CamControl />
        <Char action={charAction} />
      </MotionCanvas>
      <Loader />
    </>
  );
}

function CamControl() {
  const three = useThree();

  useFrame(() => {
    three.setSize(parentBound.width, parentBound.height);
    three.camera.aspect = parentBound.width / parentBound.height;
    three.camera.updateProjectionMatrix();
  });

  three.camera.position.set(cameraPos.x, cameraPos.y, cameraPos.z);

  return null;
}

function Lights() {
  return (
    <>
      <ambientLight intensity={0.75} />
      <pointLight
        position={[10, 10, 10]}
        intensity={1}
        color="#ffffff"
        castShadow
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}
      />

      <pointLight
        position={[5, 5, 10]}
        intensity={1}
        color="#00d4ff"
        castShadow
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}
      />
      <pointLight
        position={[-5, -5, -10]}
        intensity={1}
        color="#ff76d5"
        castShadow
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}
      />
    </>
  );
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function Char({ action, ...props }) {
  //  receiveShadow
  //  castShadow
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/char.glb");
  const { actions } = useAnimations(animations, group);

  const prevAction = usePrevious(action);

  useEffect(() => {
    if (prevAction) actions[prevAction].stop();
    actions[action].play();
  }, [actions, prevAction, action]);

  return (
    <>
      <group ref={group} {...props} dispose={null}>
        <group position={[0, -6.47, 0]} scale={[5.7, 4.9, 7.5]}>
          <primitive object={nodes.spine} />
          <skinnedMesh
            castShadow
            receiveShadow
            geometry={nodes.hair.geometry}
            material={materials.char}
            skeleton={nodes.hair.skeleton}
          />
        </group>
      </group>
    </>
  );
}

useGLTF.preload("/char.glb");
