import * as THREE from "three";
import React, { Suspense, useEffect, useState } from "react";
import { useThree } from "@react-three/fiber";
import { Canvas } from "@react-three/fiber";
import { Text } from "@react-three/drei";

var cameraPos = { x: 0, y: 0, z: 10 };
var textSize = 10;

function VideoText({ text, size }) {
  const [video] = useState(() =>
    Object.assign(document.createElement("video"), {
      src: "/bg.mp4",
      crossOrigin: "Anonymous",
      muted: true,
      loop: true,
    })
  );
  useEffect(() => {
    if (video.currentTime > 0) {
      video.currentTime = 0;
    } else {
      video.play();
    }
  }, [size]);
  return (
    <Text fontSize={size} font="./Lato.ttf" textAlign="left">
      {text}
      <meshBasicMaterial toneMapped={false}>
        <videoTexture
          attach="map"
          args={[video]}
          encoding={THREE.sRGBEncoding}
        />
      </meshBasicMaterial>
    </Text>
  );
}

function CamControl({ size, bounds, setDrawText }) {
  const three = useThree();

  useEffect(() => {
    setDrawText(false);
    if (bounds.width < 400) textSize = size - 10;
    else if (bounds.width < 500) textSize = size - 8;
    else if (bounds.width < 700) textSize = size - 4;
    else if (bounds.width < 1024) textSize = size - 2;
    else textSize = size;
    setDrawText(true);
  }, [bounds.width, size]);

  three.camera.position.set(cameraPos.x, cameraPos.y, cameraPos.z);

  return null;
}

export function Text3D({ bounds, text, size }) {
  const [drawText, setDrawText] = useState(true);

  return (
    <>
      <Canvas dpr={[1, 2]}>
        <CamControl size={size} bounds={bounds} setDrawText={setDrawText} />
        <Suspense fallback={null}>
          {drawText && <VideoText text={text} size={textSize} />}
        </Suspense>
      </Canvas>
    </>
  );
}
