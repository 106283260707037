import React from "react";
import ReactDOM from "react-dom";
//styles
import { ThemeProvider } from "styled-components";
import { GlobalStyle, LightTheme } from "./styles/globlestyle";

import AnimationContextProvider from "./contexts/animationContext";

import App from "./app";

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <ThemeProvider theme={LightTheme}>
      <AnimationContextProvider>
        <App />
      </AnimationContextProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
