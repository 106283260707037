export var portfolioData = [
  {
    id: 0,
    type: "web",
    title: "DSI Salespad",
    imageType: "",
    image: "./work/dsi.jpg",
    description: [
      "This is one of the few full-stack web-based logistics management software I built with using Laravel and Vue.js.",
      "This consists of all the functionalities of a logistics management system.",
    ],
    skills: [
      {
        name: "Vue.js",
        color: "green",
      },
      {
        name: "Laravel",
        color: "red",
      },
    ],
    gitType: "web",
    github: "http://dsi.salespad.lk/",
  },
  {
    id: 1,
    type: "",
    title: "Time Tracker",
    imageType: "",
    image: "./work/tt.jpg",
    description: [
      "This simple employee time tracking app is built with React, Node, GraphQl and MongoDB.",
    ],
    skills: [
      {
        name: "React.js",
        color: "blue",
      },
      {
        name: "Node.js",
        color: "darkGreen",
      },
      {
        name: "GraphQl",
        color: "blueViolet",
      },
      {
        name: "MongoDB",
        color: "Chartreuse",
      },
    ],
    gitType: "web",
    github: "https://timetrackerfrontend.herokuapp.com/",
  },
  {
    id: 2,
    type: "",
    title: "Sharadagunathilake.info",
    imageType: "",
    image: "./work/vanila.jpg",
    description: [
      "I developed this Web Portfolio and it's CMS from scratch, using HTML, CSS, vanilla JavaScript, PHP, and MySQL.",
      "This web portfolio showcase few projects I have built over my free time.",
    ],
    skills: [
      {
        name: "JavaScript",
        color: "Gold",
      },
      {
        name: "CSS",
        color: "DodgerBlue",
      },
      {
        name: "PHP",
        color: "LightBlue",
      },
      {
        name: "MySQL",
        color: "DarkOrange",
      },
    ],
    gitType: "web",
    github: "http://www.sharadagunathilake.info?cfn=true",
  },
  {
    id: 3,
    type: "game",
    title: "Procedural Planet Generation",
    imageType: "video",
    image:
      "http://sharadagunathilake.info/shared/image/gameDesign/unitygame/unitygame.mp4",
    description: [
      "Procedural generation is a method in game designing that creates data via algorithms rather than create data or content manually.",
      "In this unity game, every single object is generated using an algorithm.",
    ],
    skills: [
      {
        name: "Unity3D",
        color: "black",
      },
      {
        name: "C#",
        color: "Indigo",
      },
    ],
    gitType: "web",
    github: "http://www.sharadagunathilake.info/game.php?cfn=true",
  },
  {
    id: 4,
    type: "",
    title: "Steam Multiplayer Game",
    imageType: "video",
    image:
      "http://sharadagunathilake.info/shared/image/gameDesign/unrealgame/unrealgame.mp4",
    description: [
      "This Unreal engine multiplayer game can be played in Steam mode or LAN mode.",
      "This is created by using both Unreal Engine 4 Blueprints and C++ programming language.",
    ],
    skills: [
      {
        name: "Unreal Engine",
        color: "black",
      },
      {
        name: "C++",
        color: "Navy",
      },
    ],
    gitType: "web",
    github: "http://www.sharadagunathilake.info/game.php?cfn=true",
  },
  {
    id: 5,
    type: "",
    title: "Flappy Bird Clone",
    imageType: "video",
    image:
      "http://sharadagunathilake.info/shared/image/gameDesign/java/JavaGame.mp4",
    description: [
      "For this java game LWJGL is used and LWJGL is a Java library that enables cross-platform access to OpenGL in Java.",
    ],
    skills: [
      {
        name: "Java",
        color: "Orange",
      },
      {
        name: "OpenGl",
        color: "CadetBlue",
      },
    ],
    gitType: "web",
    github: "http://www.sharadagunathilake.info/game.php?cfn=true",
  },
  {
    id: 6,
    type: "",
    title: "Instagram Augmented Reality",
    imageType: "video",
    image:
      "http://sharadagunathilake.info/shared/image/gameDesign/arGame/argame.mp4",
    description: [
      "For this game project, I used the AR foundation.",
      "AR Foundation is Unity's effort to unify handheld AR development by offering a standard API that supports core functionality for both ARCore and ARKit.",
    ],
    skills: [
      {
        name: "Unity3D",
        color: "black",
      },
      {
        name: "C#",
        color: "Indigo",
      },
      {
        name: "Instagram API",
        color: "purple",
      },
    ],
    gitType: "web",
    github: "http://www.sharadagunathilake.info/game.php?cfn=true",
  },

  {
    id: 7,
    type: "software",
    title: "C# School Database System",
    imageType: "video",
    image:
      "http://sharadagunathilake.info/shared/image/softwareDesign/csharp/csharp.mp4",
    description: [
      "This C# and MySQL program was originally developed during my university KIT506 Software Designing and Development assignment.",
    ],
    skills: [
      {
        name: "C#",
        color: "Indigo",
      },
      {
        name: "MySQL",
        color: "DarkOrange",
      },
    ],
    gitType: "web",
    github: "http://www.sharadagunathilake.info/software.php?cfn=true",
  },
  {
    id: 8,
    type: "",
    title: "C++ Game Engine",
    imageType: "video",
    image:
      "http://sharadagunathilake.info/shared/image/softwareDesign/cplus/cplus.mp4",
    description: [
      "This C++ program uses a custom 3d game engine (T3d).",
      "The objective is to create a 3d game engine that can be used to create a 3d game.",
    ],

    skills: [
      {
        name: "C++",
        color: "Navy",
      },
      {
        name: "OpenGl",
        color: "CadetBlue",
      },
    ],
    gitType: "web",
    github: "http://www.sharadagunathilake.info/software.php?cfn=true",
  },
  {
    id: 9,
    type: "",
    title: "Python Painting App",
    imageType: "video",
    image:
      "http://sharadagunathilake.info/shared/image/softwareDesign/python/python.mp4",
    description: [
      "This simple Paint application is very similar to one of my favourite childhood software Microsoft paint.",
    ],

    skills: [
      {
        name: "Python",
        color: "yellow",
      },
    ],
    gitType: "web",
    github: "http://www.sharadagunathilake.info/software.php?cfn=true",
  },
];
