import React, { useEffect, useRef, useState } from "react";
import useMeasure from "react-use-measure";

import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import {
  Animator,
  MoveIn,
  MoveOut,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  Sticky,
} from "react-scroll-motion";

import { useAnimContext } from "../../contexts/animationContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

import { aboutData } from "../../data/about";

import { Text3D } from "../../scenes/text";

const Container = styled.div`
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.background};
`;

const Gohome = styled(motion.a)`
  position: fixed;
  top: 2.5%;
  left: 7.5%;
  width: 3rem;
  cursor: pointer;
  z-index: 99;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    left: calc(50% - 1.5rem);
  }
`;
const GoHomeIcon = styled(motion.div)``;

const H1Text = styled(motion.div)`
  position: fixed;
  top: 12.5%;
  left: 7.5%;
  width: 85%;
  text-align: left;
  height: 5rem;
`;

const DataContainer = styled(motion.div)`
  margin: 0;
  padding: 0;
  position: relative;
  top: 5.5rem;
  width: 80vw;
  max-width: 1024px;
  height: calc(80vh - (5rem));
  border-radius: 18px;
  background: ${(props) => props.theme.cardBackgroundPrimary};
  backdrop-filter: blur(7.5px);
  box-shadow: 15px 15px 15px ${(props) => props.theme.cardBackgroundTertiary};
  z-index: 1;
`;

const DataTitle = styled(motion.h1)`
  margin: 1rem;
  font-size: 3rem;
  color: #fff;
  text-align: center;
  width: 100%;
  height: max-content;
  background: linear-gradient(
    271deg,
    ${(props) => props.theme.headingOne} 30%,
    ${(props) => props.theme.headingTwo} 50%,
    ${(props) => props.theme.headinThree} 70%,
    ${(props) => props.theme.headingFour} 94%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    font-size: 1.5rem;
    margin: 0.5rem;
    text-align: center;
  }
`;

const DataItemContainer = styled(motion.div)`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - (6rem));
  display: flex;
  flex-direction: row;
  gap: 0.025rem;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    flex-direction: column;
  }
`;
const DataImage = styled(motion.div)`
  margin: 0 1rem;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: ${(props) => props.theme.cardBackgroundFourth};
  backdrop-filter: blur(7.5px);

  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    align-self: center;
    width: max-content;
    margin: 0;
    padding: 0.25rem;
    max-height: calc(100% - (11.5rem));
  }
  img {
    margin: 0.5rem 0.5rem;
    width: calc(100% - 1rem));
    min-width: 150px;
    max-width: 512px;
    height: calc(100% - (1rem));
    min-height: 150px;
    object-fit: scale-down;
    // object-position: top;
    top: 0;
  }
`;
const DataMainContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 0.025rem;
  margin-right: 1rem;
  justify-content: end;
  background: ${(props) => props.theme.cardBackgroundFourth};
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    background: transparent;
    backdrop-filter: blur(7.5px);
    margin-right: 0;
  }
`;

const DataSubTitle = styled(motion.h1)`
  border-radius: 10px 10px 0 0;
  position: relative;
  margin: 0 1rem;
  font-size: 1.5rem;
  text-align: center;
  left: -1rem;
  width: calc(100%);
  height: max-content;
  background: ${(props) => props.theme.button.secondary};
  color: ${(props) => props.theme.button.primary};

  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    font-size: 1.25rem;
    border-radius: 0;
    background: transparent;
  };
  }
`;

const DataDateTitle = styled(motion.h1)`
  position: relative;
  margin-top: 1rem;
  margin-bottom: -0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;

  font-size: 1.1rem;
  text-align: center;
  left: -1rem;
  width: calc(100%);
  height: max-content;
  color: ${(props) => props.theme.button.secondary};

  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
  margin-top: 0.5rem;
  margin-bottom: -1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  };
  }
`;

const TextContainer = styled(motion.div)`
  position: relative;
  top: 1rem;
  left: 0;
  width: 100%;
  height: 100%;
`;

const PText = styled(motion.div)`
  margin: 0 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  min-height: max-content;
  color: ${(props) => props.theme.text};
  text-align: justify;

  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    margin: 0.5rem;
    text-align: center;
    font-size: 1rem;
  }
`;

const ContainerVariants = {
  rest: {},
  anim: {},
  hover: {},
  press: {},
};

const HomeNavVariants = {
  rest: { scale: 0, opacity: 0 },
  anim: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 1.5,
      type: "spring",
      bounce: 0.5,
      duration: 1,
    },
  },
  hover: {},
  press: {},
};

const IconVariants = {
  rest: {},
  anim: {},
  hover: {
    scale: [1, 0.9, 1],
    transition: {
      type: "spring",
      duration: 0.5,
      bounce: 0.5,
      repeat: Infinity,
    },
  },
  press: {
    scale: [1, 1.1, 1],
    transition: {
      type: "spring",
      duration: 0.1,
      bounce: 0.5,
    },
  },
};

const H2Variants = {
  rest: {
    y: "-100vh",
  },
  anim: {
    y: 0,
    transition: {
      delay: 0.5,
    },
  },
};

const DataContainerVariants = {
  rest: {
    opacity: 0,
    y: "-100vh",
  },
  anim: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1,
    },
  },
};

const PTextVariants = {
  rest: {},
  anim: {},
};

function About() {
  const { goToHome } = useAnimContext();

  const [showWork, setShowWork] = useState(true);
  const [title, setTitle] = useState("About me");

  const [refText, boundsText] = useMeasure();
  const [refScroll, boundsScroll] = useMeasure();
  const aboutRef = useRef();
  const eduRef = useRef();
  const workeRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const { top: topAbout } = aboutRef.current.getBoundingClientRect();
      const { top: topEdu } = eduRef.current.getBoundingClientRect();
      const { top: topWork } = workeRef.current.getBoundingClientRect();

      if (topAbout !== 0) {
        setTitle("About me");
      } else if (topEdu !== 0) {
        setTitle("Education History");
      } else if (topWork !== 0) {
        setTitle("Employment History");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const SplitText = ({ children, ...rest }) => {
    let words = children.split(" ");
    return words.map((word, i) => {
      return (
        <div
          key={children + i}
          style={{ display: "inline-block", overflow: "hidden" }}
        >
          <motion.div
            {...rest}
            style={{ display: "inline-block", willChange: "transform" }}
            custom={i}
          >
            {word + (i !== words.length - 1 ? "\u00A0" : "")}
          </motion.div>
        </div>
      );
    });
  };

  return (
    <>
      <AnimatePresence>
        {showWork && (
          <>
            <Container
              ref={refScroll}
              variants={ContainerVariants}
              initial="rest"
              animate="anim"
              exit="rest"
            ></Container>
            <ScrollContainer>
              {aboutData.map((item, id) => (
                <ScrollPage page={item.id} key={id}>
                  <Animator
                    animation={batch(
                      Sticky(),
                      Fade(),
                      MoveIn(boundsScroll.width, 0),
                      MoveOut(-boundsScroll.width, 0)
                    )}
                  >
                    {item.type === "about" && <div ref={aboutRef}></div>}
                    {item.type === "education" && <div ref={eduRef}></div>}
                    {item.type === "work" && <div ref={workeRef}></div>}
                    <DataContainer
                      variants={DataContainerVariants}
                      initial="rest"
                      animate="anim"
                    >
                      <DataTitle>{item.title}</DataTitle>

                      <DataItemContainer>
                        <DataImage>
                          <img src={item.image} alt={item.title} />
                        </DataImage>
                        <DataMainContent>
                          {item.subtitle !== "" && (
                            <DataSubTitle>{item.subtitle}</DataSubTitle>
                          )}
                          {item.date !== "" && (
                            <DataDateTitle>{item.date}</DataDateTitle>
                          )}
                          <TextContainer>
                            <>
                              {item.description.map((text, i) => (
                                <PText key={text + i} variants={PTextVariants}>
                                  <SplitText
                                    initial={{ y: "100%" }}
                                    animate="visible"
                                    variants={{
                                      visible: (i) => ({
                                        y: 0,
                                        transition: {
                                          delay: i * 0.05,
                                        },
                                      }),
                                    }}
                                  >
                                    {text}
                                  </SplitText>
                                </PText>
                              ))}
                            </>
                          </TextContainer>
                        </DataMainContent>
                      </DataItemContainer>
                    </DataContainer>
                  </Animator>
                </ScrollPage>
              ))}
            </ScrollContainer>

            <Gohome
              variants={HomeNavVariants}
              initial="rest"
              animate="anim"
              exit="rest"
              onClick={() => {
                goToHome();
                setShowWork(false);
              }}
            >
              <GoHomeIcon
                variants={IconVariants}
                whileHover="hover"
                whileTap="press"
                style={{
                  color: "white",
                }}
              >
                <FontAwesomeIcon icon={faHome} size="3x" />
              </GoHomeIcon>
            </Gohome>
            <H1Text
              ref={refText}
              variants={H2Variants}
              initial="rest"
              animate="anim"
              exit="rest"
            >
              <Text3D bounds={boundsText} text={title} size={16} />
            </H1Text>
          </>
        )}
      </AnimatePresence>
    </>
  );
}

export default About;
