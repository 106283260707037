import * as THREE from "three";
import { motion } from "framer-motion-3d";
import { MotionConfig, useSpring, useTransform } from "framer-motion";
import { useRef, useLayoutEffect, useEffect } from "react";
import { Canvas, useThree, useFrame } from "@react-three/fiber";
import { useGLTF, useAnimations, Preload } from "@react-three/drei";

const spring = { stiffness: 600, damping: 30 };
const mouseToLightRotation = (v) => (-1 * v) / 140;

function useSmoothTransform(value, springOptions, transformer) {
  return useSpring(useTransform(value, transformer), springOptions);
}

function Camera({ mouseX, mouseY, isHover, welcomeAnimDone, ...props }) {
  let restCameraPos = { x: -2.5, y: 10, z: 45 };
  let animCameraPos = { x: 0, y: 10, z: 30 };

  let cameraPos = new THREE.Vector3(-1.25, 10, 40);

  let restLookAt = { x: -5, y: 1, z: 0 };
  let animLookAt = { x: 0, y: -1, z: 0 };

  let lookAt = new THREE.Vector3(-2.5, 0, 0);

  const set = useThree(({ set }) => set);
  const camera = useThree(({ camera }) => camera);
  const size = useThree(({ size }) => size);
  const cameraRef = useRef();

  useLayoutEffect(() => {
    const { current: cam } = cameraRef;
    if (cam) {
      cam.aspect = size.width / size.height;
      cam.updateProjectionMatrix();
    }
  }, [size, props]);

  useLayoutEffect(() => {
    if (cameraRef.current) {
      const oldCam = camera;
      set(() => ({ camera: cameraRef.current }));
      return () => set(() => ({ camera: oldCam }));
    }
  }, [camera, cameraRef, set]);

  useFrame(() => {
    if (isHover || !welcomeAnimDone) {
      cameraPos.lerp(animCameraPos, 0.075);
      lookAt.lerp(animLookAt, 0.075);
    } else {
      cameraPos.lerp(restCameraPos, 0.075);
      lookAt.lerp(restLookAt, 0.075);
    }
    camera.position.set(cameraPos.x, cameraPos.y, cameraPos.z);
    camera.lookAt(lookAt);
  });

  return <motion.perspectiveCamera ref={cameraRef} fov={20} />;
}

export function WelcomeBtnScene({ welcomeAnimDone, isHover, mouseX, mouseY }) {
  const transition = {
    type: "spring",
    bounce: 0.5,
    duration: 0.7,
  };

  const lightRotateX = useSmoothTransform(mouseY, spring, mouseToLightRotation);
  const lightRotateY = useSmoothTransform(mouseX, spring, mouseToLightRotation);

  return (
    <>
      <Canvas shadows dpr={[1, 2]} resize={{ scroll: false, offsetSize: true }}>
        <Camera
          mouseX={mouseX}
          mouseY={mouseY}
          isHover={isHover}
          welcomeAnimDone={welcomeAnimDone}
        />
        <MotionConfig transition={transition}>
          <motion.group
            center={[0, 0, 0]}
            rotation={[lightRotateX, lightRotateY, 0]}
          >
            <Lights />
          </motion.group>
          <motion.group initial={false} dispose={null}>
            <Char
              action={!welcomeAnimDone ? "greet" : isHover ? "greet" : "idle"}
            />
          </motion.group>
        </MotionConfig>
      </Canvas>
    </>
  );
}

function Lights() {
  return (
    <>
      <ambientLight intensity={0.75} />
      <pointLight
        position={[10, 10, 10]}
        intensity={1}
        color="#ffffff"
        castShadow
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}
      />

      <pointLight
        position={[5, 5, 10]}
        intensity={1}
        color="#00d4ff"
        castShadow
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}
      />
      <pointLight
        position={[-5, -5, -10]}
        intensity={1}
        color="#ff76d5"
        castShadow
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}
      />
    </>
  );
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function Char({ action, ...props }) {
  //  receiveShadow
  //  castShadow
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/char.glb");
  const { actions } = useAnimations(animations, group);
  const prevAction = usePrevious(action);

  useEffect(() => {
    if (prevAction) actions[prevAction].stop();

    actions[action].play();
  }, [actions, prevAction, action]);

  return (
    <>
      <group ref={group} {...props} dispose={null}>
        <group position={[0, -6.47, 0]} scale={[5.7, 4.9, 7.5]}>
          <primitive object={nodes.spine} />
          <skinnedMesh
            castShadow
            receiveShadow
            geometry={nodes.hair.geometry}
            material={materials.char}
            skeleton={nodes.hair.skeleton}
          />
          <Preload all />
        </group>
      </group>
    </>
  );
}

useGLTF.preload("/char.glb");
