import React, { createContext, useContext, useState, useEffect } from "react";

const animContext = createContext();
export const useAnimContext = () => useContext(animContext);

function AnimationContextProvider(props) {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0.0);
  const [isMobilea, setIsMobilea] = useState(true);
  const [welcomed, setWelcomed] = useState(false);
  const [page, setPage] = useState("");
  const [goToRest, setGoToRest] = useState(false);
  const [welcomeBtnClicked, setWelcomeBtnClicked] = useState(false);
  const [aboutBtnClicked, setAboutBtnClicked] = useState(false);
  const [workBtnClicked, setWorkBtnClicked] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setProgress(100);
    }, 7500);
  }, []);

  const goToHome = () => {
    if (isMobilea) {
      setPage("home");
    } else {
      setPage("");
      if (welcomed) setGoToRest(true);
      setWelcomeBtnClicked(true);
      setAboutBtnClicked(false);
      setWorkBtnClicked(false);
      setWelcomed(true);
    }
  };

  const gotoAbout = () => {
    if (isMobilea) {
      setPage("about");
    } else {
      setPage("");
      setGoToRest(true);
      setWelcomeBtnClicked(false);
      setAboutBtnClicked(true);
      setWorkBtnClicked(false);
    }
  };

  const gotoWork = () => {
    if (isMobilea) {
      setPage("work");
    } else {
      setPage("");
      setGoToRest(true);
      setWelcomeBtnClicked(false);
      setAboutBtnClicked(false);
      setWorkBtnClicked(true);
    }
  };
  const values = {
    loading,
    setLoading,
    progress,
    setProgress,
    isMobilea,
    setIsMobilea,
    page,
    setPage,
    goToRest,
    setGoToRest,
    welcomeBtnClicked,
    setWelcomeBtnClicked,
    goToHome,
    aboutBtnClicked,
    setAboutBtnClicked,
    gotoAbout,
    workBtnClicked,
    setWorkBtnClicked,
    gotoWork,
  };
  return (
    <animContext.Provider value={values}>{props.children}</animContext.Provider>
  );
}

export default AnimationContextProvider;
